<template>
  <div class="screenx row" style="margin-top:90px; margin-bottom:200px;">

    <div class="offset-12 col-12 offset-xs-12 col-xs-12 offset-sm-12 col-sm-12 offset-md-12 col-md-12 offset-lg-2 col-lg-8 offset-xl-3 col-xl-6">

      <div class="row py-5 px-5">
        <div class="col-8 offset-2 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2 mb-5 lead text-center">
          <div class="mb-4 text-center display-5" v-html="page.title"></div>
          <button
            v-if="page.code"
            type="button"
            class="btn btn-primary btn-lg px-4 gap-3 mt-4"
            v-html="'Learn more about ' + page.code.toUpperCase()"
            @click="urlRedirect('https://adamsws.com')"
          ></button>
        </div>
        <div
          class="col-12"
          v-for="para in page.details"
          v-bind:key="para['.key']"
        >
          <p class="lead" v-html="para.title"></p>
        </div>
        <div
          v-for="item in services"
          v-bind:key="item['.key']"
          class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 py-3"
        >
          <div
            v-if="item.show"
            class="row"
          >
            <div
              class="col-1"
            >&bull;</div>
            <div
              class="col-11"
              :to="item.link"
              v-html="item.title"
            ></div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>
<script>
export default {
  name: 'Services',
  mounted () {
    window.scrollTo(0, 0)
  },
  methods: {
    urlRedirect (url) {
      if ([null, undefined].includes(url)) { return }
      location.href = url
    }
  },
  data () {
    return {
      page: {
        title: 'Services',
        details: [
          {
            title: 'AVIAMS Consult provides its clients, a wide range of consultancy services ranging from safety strategic planning to safety inspector training tailored to keeping the CAA up to standard and efficient as regards to safety oversight.'
          },
          {
            title: 'AVIAMS Consult provided one year Technical Consultancy Services to GCAA in preparation and through its last ICAO ICVM Audit which saw Ghana emerge with the highest ICAO Effective Implementation (EI) score in Africa of 89.98% in March 2019.'
          },
          {
            title: 'AVIAMS Consult also provides customized training in various Aviation Courses including Resolution of Safety and Security Concerns for Civil Aviation Personnel.'
          },
          {
            title: 'Our instructors are ICAO Certified to provide training for Government Safety Inspectors in Air Operator Certification (AOC), Approved Maintenance Organisation (AMO) Certification and Personnel Licensing.'
          },
          {
            title: 'We render the following safety oversight consultancy services to our clients:'
          }
        ]
      },
      services: [
        {
          title: 'Assistance in preparation for ICAO or EASA Audits',
          show: true,
          link: ''
        },
        {
          title: 'Development and implementation of Safety management regulatory and oversight tools',
          show: true,
          link: ''
        },
        {
          title: 'Formal Course training and OJT Instruction for Safety Oversight staff',
          show: true,
          link: ''
        },
        {
          title: 'Review and recommendations on organisation structure for state and regional safety oversight organisations',
          show: true,
          link: ''
        },
        {
          title: 'Technological solutions to support CAAs and RSOOs in documenting and tracking state, regional and international safety oversight processes',
          show: true,
          link: ''
        },
        {
          title: 'Policy implementation advice and support',
          show: true,
          link: ''
        },
        {
          title: 'SSP development and implementation',
          show: true,
          link: ''
        },
        {
          title: 'Safety Management System (SMS) implementation',
          show: true,
          link: ''
        },
        {
          title: 'SSP and SMS training',
          show: true,
          link: ''
        },
        {
          title: 'Support to states in performing Certification and Surveillance activities according to regional and international standards',
          show: true,
          link: ''
        },
        {
          title: 'Guidance on technical procedures to promote and improve safety management oversight',
          show: true,
          link: ''
        },
        {
          title: 'Creation of specific Safety Performance Indicators (SPIs)',
          show: true,
          link: ''
        },
        {
          title: 'Support in establishing a cooperative relationship with industry',
          show: true,
          link: ''
        }
        /*
        ,
        {
          title: 'PBR analysis and transformation plan development',
          show: false,
          link: ''
        },
        {
          title: 'Strategy and business plan definition',
          show: false,
          link: ''
        },
        {
          title: 'Safety and other regulatory objectives definition',
          show: false,
          link: ''
        }
        */
      ]
    }
  }
}
</script>
